body {
  background: url(/images/boromore.png);
  background-repeat: no-repeat;
  background-size: 20%;
  background-color: #19a974;
  color: #222;
  font-family: sans-serif;
}

h1, nav, header, footer {
  text-align: center;
  padding: 1em 2em;
	margin-bottom: 1em;
}

header, footer {
  background-color: #19a974;
  padding: 1em 2em;
	margin-bottom: 1em;
}

footer { 
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
	padding: 1em 2em;
}

main {
  margin: 4rem auto;
  max-width: 60rem;
  text-align: center;
}

.col1,.col2,.col3
{
	float: left;
	background-color: #eee;
	margin-bottom: 1em;
	margin-left: 3%;
}

.col1 { width: 48%; }
.col2 { width: 20%; }
.col3 { width: 20%; }
